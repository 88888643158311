import { storeToRefs } from 'pinia'

import { useSegmentTrack } from '~/composables/Segment/useSegmentTrack'

import { useInfluencersInteractionsStore } from '~/stores/influencersInteractions'
import { useTagStore } from '~/stores/tag'
import { useUserBandStore } from '~/stores/userBand'
import { useUserFavoritesStore } from '~/stores/userFavorites'

import type { Influencer, StatsV3Influencer } from '~/types/influencer'
import type { StoreInfluencerInteraction } from '~/types/influencerInteraction'
import type {
  InfluencerTrackingInfo,
  OriginPageName,
} from '~/types/Segment/SegmentEventLibrary/SegmentEventLibraryBucketList'

export function useSegmentBucketList() {
  const { $pinia } = useNuxtApp()
  const route = useRoute()
  const segmentTrack = useSegmentTrack()

  const { BAND_TRACKING_INFO } = storeToRefs(useUserBandStore($pinia))
  const { ORIGIN_PAGE, CAMPAIGN_ID } = storeToRefs(
    useUserFavoritesStore($pinia),
  )

  const { GET_TAG_FROM_ID } = useTagStore($pinia)
  const { GET_INTERACTION_FROM_INFLUENCER_ID } =
    useInfluencersInteractionsStore($pinia)

  function computeInfluencerInteraction(
    influencerId: number,
  ): null | StoreInfluencerInteraction {
    return GET_INTERACTION_FROM_INFLUENCER_ID(influencerId) ?? null
  }

  function getInfluencerTrackingInfo(
    influencers: (Influencer | StatsV3Influencer)[] | null,
  ): InfluencerTrackingInfo {
    const curatorBadgesList = new Set<string>()
    const curatorCountriesList = new Set<string>()
    const curatorIdsList = new Set<number>()
    const curatorNamesList = new Set<string>()
    const curatorMusicGenresList = new Set<string>()
    const curatorTypesList = new Set<string>()
    let numTracksSent = 0

    if (influencers && influencers.length) {
      const alreadyAddedInfluencers: { [infId: number]: boolean } = {}

      influencers.forEach((influencer: Influencer | StatsV3Influencer) => {
        const influencerBadge = influencer.tags.identity.influencer_badge || []
        influencerBadge
          .map((tagID: number) => GET_TAG_FROM_ID(tagID)?.name as string)
          .forEach(curatorBadgesList.add, curatorBadgesList)

        const country = influencer.tags.identity.country || []
        country
          .map((tagID: number) => GET_TAG_FROM_ID(tagID)?.name as string)
          .forEach(curatorCountriesList.add, curatorCountriesList)

        const influencerKind = influencer.tags.identity.influencer_kind || []
        influencerKind
          .map((tagID: number) => GET_TAG_FROM_ID(tagID)?.name as string)
          .forEach(curatorTypesList.add, curatorTypesList)

        const subgenre = influencer.tags.liked?.subgenre || []
        subgenre
          .map((tagID: number) => GET_TAG_FROM_ID(tagID)?.name as string)
          .forEach(curatorMusicGenresList.add, curatorMusicGenresList)

        curatorIdsList.add(influencer.id)
        curatorNamesList.add(influencer.entity)

        if (!alreadyAddedInfluencers[influencer.id]) {
          numTracksSent +=
            computeInfluencerInteraction(influencer.id)?.total ?? 0
          alreadyAddedInfluencers[influencer.id] = true
        }
      })
    }
    return {
      curator_badges_list: [...curatorBadgesList],
      curator_countries_list: [...curatorCountriesList],
      curator_ids_list: [...curatorIdsList],
      curator_names_list: [...curatorNamesList],
      curator_music_genres_list: [...curatorMusicGenresList],
      curator_types_list: [...curatorTypesList],
      num_tracks_sent: numTracksSent,
    }
  }

  function trackListCreationInitiated(): void {
    segmentTrack('List - List Creation Initiated', {
      ...BAND_TRACKING_INFO.value,
      id_campaign: CAMPAIGN_ID.value,
      curator_saved_from: ORIGIN_PAGE.value as OriginPageName,
      list_created_from: ORIGIN_PAGE.value as OriginPageName,
    })
  }

  function trackListCreationFinalized(bucketName: string): void {
    segmentTrack('List - List Creation Finalized', {
      ...BAND_TRACKING_INFO.value,
      id_campaign: CAMPAIGN_ID.value,
      list_name: bucketName,
      curator_saved_from: ORIGIN_PAGE.value as OriginPageName,
      list_created_from: ORIGIN_PAGE.value as OriginPageName,
    })
  }

  function trackListSaveInitiated(
    influencers: (Influencer | StatsV3Influencer)[] | null,
  ): any {
    const details = {
      ...BAND_TRACKING_INFO.value,
      ...getInfluencerTrackingInfo(influencers),
      id_campaign: CAMPAIGN_ID.value,
      curator_saved_from: ORIGIN_PAGE.value as OriginPageName,
      // TODO: fix this - it's missing "num_curators_saved_to_list"
    }
    segmentTrack('List - Save Initiated', details)
    return details // temporary hack for existing events of legacy system
  }

  function trackListSaveFinalized(
    influencers: (Influencer | StatsV3Influencer)[] | null,
    numCuratorsInList: number,
    numCuratorsSavedToList: number,
    listName: string,
  ): any {
    const details = {
      ...BAND_TRACKING_INFO.value,
      ...getInfluencerTrackingInfo(influencers),
      id_campaign: CAMPAIGN_ID.value,
      curator_saved_from: ORIGIN_PAGE.value as OriginPageName,
      num_curators_in_list: numCuratorsInList,
      num_curators_saved_to_list: numCuratorsSavedToList,
      page_title: route.name?.toString() || 'no route name',
      list_name: listName,
    }
    segmentTrack('List - Save Finalized', details)
    return details // temporary hack for existing events of legacy system
  }

  return {
    trackListCreationInitiated,
    trackListCreationFinalized,
    trackListSaveInitiated,
    trackListSaveFinalized,
    getInfluencerTrackingInfo,
  } as const
}
